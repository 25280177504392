import React, { useContext, useState } from 'react';

const AppContext = React.createContext();

export function useAppContext() {
    return useContext(AppContext)
}

export function AppContextProvider({ children }) {
    const [currentUser, setCurrentUser] = useState();
    const [language, setLanguage] = useState("EN");
    const [token, setToken] = useState("");

    const value = {
        currentUser,
        setCurrentUser,
        language,
        setLanguage,
        token,
        setToken
    }

    return (
        <AppContext.Provider value={value}>
            {children}
        </AppContext.Provider>
    )
} 