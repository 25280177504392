const Complete = (props) => {
    const {onViewMyCourseClick} = props;

    const onClickHandler = () => {
     
    }

    return (
        <div className="container p-3 mt-3">

           

            <div className="card-body">

                <div className="d-flex align-items-center justify-content-center">
                 <div className="text-center pt-5 pb-5"> <h5 style={{ color: '#2a8639' }}>  You have completed the enrollment process.</h5>
                 <h5 style={{ color: '#2a8639' }}>  You may now view your Enrolled Courses.</h5> 
                 <button style={{ backgroundColor: 'rgb(85, 62, 148)', color: 'white', border: 'none' }} className="btn mt-3" onClick={() => window.top.location.href = 'https://seiu775bgsandbox1.docebosaas.com/pages/116/my-courses'}>View My Courses</button></div>
                </div>
            </div>


        </div>
    )
}

export default Complete;
