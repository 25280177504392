import axios from 'axios';

const endPoint = `${process.env.REACT_APP_API_DOMAIN}/poweruser`;

export const queryBranches = async (userId, searchText) => {
    let url = `${endPoint}/${userId}/branches${window.location.search}&page_size=50${searchText ? `&search=${searchText}` : ""}`
    const response = await axios.get(url, { withCredentials: true });
    return response.data.items
}

export const queryUsers = async (userId, searchText) => {
    let url = `${endPoint}/${userId}/assignedusers${window.location.search}&page_size=50${searchText ? `&search=${searchText}` : ""}`
    //let url = `${process.env.REACT_APP_API_DOMAIN}/user?page_size=50${searchText ? `&search_text=${searchText}` : ""}`
    const response = await axios.get(url, { withCredentials: true });
    return response.data.items
}

export const queryGroups = async (userId, searchText) => {
    
    let url = `${endPoint}/${userId}/groups${window.location.search}&page_size=50${searchText ? `&search=${searchText}` : ""}`
    const response = await axios.get(url, { withCredentials: true });
    return response.data.items
}

export const getAllGroupMembers = async (groupId) => {
    let url = `${endPoint}/groupmembers/${groupId}${window.location.search}`
    const response = await axios.get(url, { withCredentials: true });
    return response.data;
}